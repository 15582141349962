import {IWeb3Facade} from "../IWeb3Facade";
import {
  AddressType, BalanceDataByAddress,
  EstimateResultType, IAccount, IDataForGenerateTransactions, IDataForSendTransactions,
  IGeneralTxData,
  IMapValueByAddress,
  ITokenDict,
  ITransactionPriorityEnum, NetworkCurrencyEnum,
  NetworkType, PrivateKeyType
} from "../../types";
import {setProviderWeb3, SUI_BASE_COIN_TYPE, Web3SuiType} from "../../../../store/web3/web3Sui";
import {SUITokens} from "../../../../store/suiscan/SuiTokens";
import {GasHelper, sendRequestDelay} from "../../../../helpers";
import * as Sentry from "@sentry/react";
import {MIST_PER_SUI} from "@mysten/sui/utils";

const TransactionPriorityEnum: ITransactionPriorityEnum = {
  medium: "medium",
} as const

export interface IDataForGenerateSUITransactions extends IDataForGenerateTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  receiverAddress: AddressType,
  privateKeyByAddress: IMapValueByAddress<PrivateKeyType>,
}

export interface ITxSuiData extends IGeneralTxData {
  amount: bigint,
  receiverAddress: AddressType,
  gasPrice: bigint,
  senderAccount: {address: AddressType, privateKey: PrivateKeyType},
  gas?: bigint,
  coinType?: string
}

class SUIFacade implements IWeb3Facade {
  readonly defaultTransactionPriority: keyof ITransactionPriorityEnum;
  readonly linkForTxScan: string;
  readonly network: NetworkType;
  readonly tokensDict: ITokenDict;
  readonly transactionPriorityOptions: ITransactionPriorityEnum;
  readonly addressesChunkSize: number;
  readonly limitPrivateKeys: number;
  protected readonly _web3Provider: Web3SuiType;

  constructor() {
    this.defaultTransactionPriority = TransactionPriorityEnum.medium
    this.transactionPriorityOptions = {
      [TransactionPriorityEnum.medium]: "Average",
    }
    this.linkForTxScan = process.env.REACT_APP_FOR_TX_SUI_SCAN
    this.network = 'sui'
    this.tokensDict = SUITokens

    this._web3Provider = setProviderWeb3(process.env.REACT_APP_SUI_WEB3_HTTP_PROVIDER);

    this.limitPrivateKeys = 15000;
    this.addressesChunkSize = 900;
  }

  getAddressesChunkSize(): number {
    return this.addressesChunkSize;
  }

  getLimitPrivateKeys(): number {
    return this.limitPrivateKeys;
  }

  getTimeout(): number {
    return 50;
  }

  async fetchBaseCurrencyBalanceDataByAddress(addressList: Set<AddressType>): Promise<BalanceDataByAddress> {
    const balanceByAddress: BalanceDataByAddress = new Map();
    const promises: Promise<boolean>[] = [];
    let counter: number = 0;

    const {getBalance} = this._web3Provider;

    for (const address of addressList.values()) {
      const getBalanceRequest = () => getBalance(address)
        .then((balanceData) => {
          balanceByAddress.set(address, BigInt(balanceData.totalBalance));
          return true;
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              section: "IWeb3Facade",
              facade: "SIUFacade",
              method: "fetchBaseCurrencyBalanceDataByAddress"
            },
            contexts: {
              "fetchBalanceDataByAddress": {
                network: this.network,
                currency: NetworkCurrencyEnum[this.network],
                count_keys: addressList.size,
              }
            }
          });
          return false;
        });

      promises.push(sendRequestDelay(getBalanceRequest, this.getTimeout() * counter))
      counter++;
    }

    await Promise.all(promises);

    return balanceByAddress;
  }

  async generateTransactions(data: IDataForGenerateSUITransactions): Promise<EstimateResultType> {
    const {receiverAddress, baseCurrencyBalanceData, privateKeyByAddress} = data;

    const txDataByAddress: IMapValueByAddress<ITxSuiData> = new Map()

    const {getGasPrice} = this._web3Provider;

    const {done} = baseCurrencyBalanceData.entries().next()
    if (done) {
      return {txDataByAddress, feeDataByAddress: new Map()}
    }

    const gasPrice = await getGasPrice();

    for (const [address, balance] of baseCurrencyBalanceData.entries()) {
      if (balance > 0 && address !== receiverAddress) {
        txDataByAddress.set(address, {
          receiverAddress,
          amount: baseCurrencyBalanceData.get(address)!,
          senderAccount: {address, privateKey: privateKeyByAddress.get(address)!},
          gasPrice,
          gas: BigInt(0),
          coinType: SUI_BASE_COIN_TYPE
        });
      }
    }

    return this.estimateFee(txDataByAddress);
  }

  async estimateFee(txDataByAddress: IMapValueByAddress<ITxSuiData>): Promise<EstimateResultType> {
    const feeDataByAddress: IMapValueByAddress<bigint> = new Map()

    const promises: Promise<boolean>[] = [];
    let counter: number = 0;

    const {estimateSingleTransferTransactionFee} = this._web3Provider;

    for (const [address, txData] of txDataByAddress.entries()) {
      const estimateFeeRequest = () => estimateSingleTransferTransactionFee(txData)
        .then((fee) => {
          const summaryFee = GasHelper.gasPricePlusPercent(fee, 20);
          feeDataByAddress.set(address, summaryFee);

          const txData = txDataByAddress.get(address)!;
          txData.gas = summaryFee;
          txDataByAddress.set(address, txData);
          return true;
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              section: "IWeb3Facade",
              facade: "SUIFacade",
              method: "estimateFee"
            },
            contexts: {
              "estimateFee": {
                network: this.network,
                currency: NetworkCurrencyEnum[this.network],
                count_addresses: txDataByAddress.size,
              }
            }
          });
          return false;
        });

      promises.push(sendRequestDelay(estimateFeeRequest, this.getTimeout() * counter))
      counter++;
    }

    await Promise.all(promises);

    return {txDataByAddress, feeDataByAddress};
  }

  async sendTransactions(data: IDataForSendTransactions): Promise<IMapValueByAddress> {
    const {transactionDataByAddress} = data

    const resultTxReceipt: IMapValueByAddress<string> = new Map();
    const promises: Promise<boolean>[] = [];
    let counter: number = 0;

    const {sendSingleTransferTransaction, getGasPrice} = this._web3Provider

    const gasPrice = await getGasPrice();

    for (const [senderAddress, tx] of transactionDataByAddress.entries()) {
      let txData = tx as ITxSuiData;
      txData.gasPrice = gasPrice;

      if (txData.gas! !== BigInt(0) && txData.gas! < txData.amount) {
        const sendTransactionRequest = () => sendSingleTransferTransaction(txData)
          .then(txHash => {
            resultTxReceipt.set(senderAddress, txHash);
            return true;
          })
          .catch((error) => {
            Sentry.captureException(error, {
              tags: {
                section: "IWeb3Facade",
                facade: "SUIFacade",
                method: "sendTransactions"
              },
              contexts: {
                "sendTransactions": {
                  network: this.network,
                  currency: NetworkCurrencyEnum[this.network],
                  count_addresses: transactionDataByAddress.size,
                }
              }
            });
            return false;
          });

        promises.push(sendRequestDelay(sendTransactionRequest, this.getTimeout() * counter))
        counter++;
      }
    }

    await Promise.all(promises);

    return resultTxReceipt;
  }

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    return {
      address: this._web3Provider.getAddressByPrivateKey(privateKey),
      privateKey
    };
  }

  toBaseCurrencyFromUnit(amount: bigint | string | number): string {
    return (Number(amount) / Number(MIST_PER_SUI)).toString();
  }

  toUnitFromBaseCurrency(amount: string | number): bigint {
    return BigInt(amount) * MIST_PER_SUI;
  }

  validateAddress(address: AddressType): boolean {
    return this._web3Provider.isAddressValid(address);
  }

  resetGasPriceAndNonce(): void {
  }
}

export {SUIFacade}