import {IWeb3TokenFacade} from "../IWeb3TokenFacade";
import {IDataForGenerateSUITransactions, ITxSuiData, SUIFacade} from "./SUIFacade";
import {
  AddressType,
  BalanceDataByAddress,
  EstimateResultType,
  IMapValueByAddress,
  ITokenInfo, NetworkCurrencyEnum
} from "../../types";
import {SUI_DEFAULT_IMG} from "../../../../store/suiscan/SuiTokens";
import * as Sentry from "@sentry/react";
import {sendRequestDelay} from "../../../../helpers";

class SUICoinFacade extends SUIFacade implements IWeb3TokenFacade {
  readonly defaultTokenImage: string;

  constructor() {
    super();
    this.defaultTokenImage = SUI_DEFAULT_IMG;
  }

  async fetchTokenInfo(address: AddressType): Promise<ITokenInfo> {
    const {getCoinInfo} = this._web3Provider;

    const tokenMetaData = await getCoinInfo(address);
    if (!tokenMetaData) {
      throw new Error("Coin not found");
    }

    return {
      symbol: tokenMetaData.symbol,
      title: tokenMetaData.name,
      address,
      decimal: tokenMetaData.decimals,
      img: this.tokensDict[tokenMetaData?.symbol]?.img || this.defaultTokenImage
    };
  }

  async fetchTokenBalanceDataByAddress(addressList: Set<AddressType>, tokenAddress: AddressType): Promise<BalanceDataByAddress> {
    const tokenBalanceByAddress: BalanceDataByAddress = new Map();
    const promises: Promise<boolean>[] = [];
    let counter: number = 0;

    const {getBalance} = this._web3Provider;

    for (const address of addressList.values()) {
      const getBalanceRequest = () => getBalance(address, tokenAddress)
        .then((balanceData) => {
          tokenBalanceByAddress.set(address, BigInt(balanceData.totalBalance));
          return true;
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              section: "IWeb3Facade",
              facade: "SIUCoinFacade",
              method: "fetchTokenBalanceDataByAddress"
            },
            contexts: {
              "fetchTokenBalanceDataByAddress": {
                network: this.network,
                currency: NetworkCurrencyEnum[this.network],
                count_keys: addressList.size,
              }
            }
          });
          return false;
        });

      promises.push(sendRequestDelay(getBalanceRequest, this.getTimeout() * counter))
      counter++;
    }

    await Promise.all(promises);

    return tokenBalanceByAddress;
  }

  async generateTransactions(data: IDataForGenerateSUITransactions, tokenAddress?: AddressType): Promise<EstimateResultType> {
    if (!tokenAddress) return super.generateTransactions(data);

    const {receiverAddress, baseCurrencyBalanceData, privateKeyByAddress, tokenBalanceData} = data;

    const txDataByAddress: IMapValueByAddress<ITxSuiData> = new Map()

    const {getGasPrice} = this._web3Provider;

    const {done} = baseCurrencyBalanceData.entries().next()
    if (done) {
      return {txDataByAddress, feeDataByAddress: new Map()}
    }

    const gasPrice = await getGasPrice();

    for (const [address, balance] of tokenBalanceData.entries()) {
      if (balance > 0 && address !== receiverAddress) {
        txDataByAddress.set(address, {
          receiverAddress,
          amount: tokenBalanceData.get(address)!,
          senderAccount: {address, privateKey: privateKeyByAddress.get(address)!},
          gasPrice,
          gas: BigInt(0),
          coinType: tokenAddress
        });
      }
    }

    return this.estimateFee(txDataByAddress);
  }
}

export { SUICoinFacade };