import {AddressType, IMapValueByAddress, PrivateKeyType} from "../../pages/ConsolidationTool/types";
import {isValidSuiAddress} from '@mysten/sui/utils';
import {Ed25519Keypair} from '@mysten/sui/keypairs/ed25519';
import {CoinBalance, CoinMetadata, CoinStruct, SuiClient} from "@mysten/sui/client";
import {ITxSuiData} from "../../pages/ConsolidationTool/facades/SUI_Network/SUIFacade";
import {coinWithBalance, Transaction} from "@mysten/sui/transactions";

export const SUI_BASE_COIN_TYPE = '0x2::sui::SUI' as const;
const SUI_DEFAULT_GAS_BUDGET = BigInt(3_000_000);
const SUI_DEFAULT_FEE = BigInt(2_000_000);

export type IMultipleTransferSui = {
  recipients: IMapValueByAddress<bigint>,
  senderAccount: {address: AddressType, privateKey: PrivateKeyType},
  gasPrice: bigint,
}

export function setProviderWeb3(apiKey: string) {
  const client = new SuiClient({url: apiKey});

  function isAddressValid(address: AddressType): boolean {
    return isValidSuiAddress(address);
  }

  function getAddressByPrivateKey(privateKey: PrivateKeyType): AddressType {
    const keypair = Ed25519Keypair.fromSecretKey(privateKey);

    return keypair.toSuiAddress();
  }

  async function getBalance(address: AddressType, coinType: string = SUI_BASE_COIN_TYPE): Promise<CoinBalance> {
    return await client.getBalance({owner: address, coinType});
  }

  async function getCoins(address: AddressType, coinType: string = SUI_BASE_COIN_TYPE): Promise<CoinStruct[]> {
    let hasNextPage = false;
    let nextCursor: string|null|undefined = null;
    let coins: CoinStruct[] = [];

    do {
      const coinsPaginateData = await client.getCoins({
        owner: address,
        coinType: coinType,
        cursor: nextCursor,
      });
      hasNextPage = coinsPaginateData.hasNextPage;
      nextCursor = coinsPaginateData.nextCursor;
      coins = [...coins, ...coinsPaginateData.data];
    } while (hasNextPage);

    return coins;
  }

  async function estimateSingleTransferTransactionFee(txData: ITxSuiData): Promise<bigint> {
    const {receiverAddress, senderAccount, amount, gasPrice, coinType} = txData;

    const tx = new Transaction();
    tx.setSender(senderAccount.address);
    tx.setGasPrice(gasPrice);
    tx.transferObjects([coinWithBalance({ balance: amount, type: coinType })], receiverAddress);

    return await runDryTransaction(tx);
  }

  async function estimateMultipleTransfersTransactionFee(data: IMultipleTransferSui): Promise<bigint> {
    const tx = buildMultipleTransfersTransaction(data);

    return await runDryTransaction(tx);
  }

  async function runDryTransaction(tx: Transaction): Promise<bigint> {
    try {
      const dryRunResult = await client.dryRunTransactionBlock({
        transactionBlock: await tx.build({ client }),
      });

      return BigInt(dryRunResult.effects.gasUsed.computationCost) +
        BigInt(dryRunResult.effects.gasUsed.storageCost) -
        BigInt(dryRunResult.effects.gasUsed.storageRebate);
    } catch (error) {
      console.error(error);
      return SUI_DEFAULT_FEE;
    }
  }

  function buildMultipleTransfersTransaction(data: IMultipleTransferSui): Transaction {
    const {recipients, senderAccount, gasPrice} = data;

    const tx = new Transaction();
    tx.setSender(senderAccount.address);
    tx.setGasPrice(gasPrice);

    recipients.forEach((amount, address) => {
      const [coin] = tx.splitCoins(tx.gas, [amount + SUI_DEFAULT_GAS_BUDGET]);
      tx.transferObjects([coin], tx.pure.address(address));
    });

    return tx;
  }

  async function sendMultipleTransferTransaction(data: IMultipleTransferSui): Promise<string> {
    const {senderAccount} = data;
    const signer = Ed25519Keypair.fromSecretKey(senderAccount.privateKey);

    const tx = buildMultipleTransfersTransaction(data);

    const result = await client.signAndExecuteTransaction({transaction: tx, signer});
    return result.digest;
  }

  async function sendSingleTransferTransaction(txData: ITxSuiData, isSendAll: boolean = true): Promise<string> {
    const {receiverAddress, senderAccount, amount, coinType} = txData;
    const signer = Ed25519Keypair.fromSecretKey(senderAccount.privateKey);

    const tx = new Transaction();
    const estimatedGas = SUI_DEFAULT_GAS_BUDGET;
    let amountToSend: bigint;

    if (coinType !== SUI_BASE_COIN_TYPE || !isSendAll) {
      amountToSend = amount;
    } else {
      amountToSend = amount - estimatedGas;
    }

    tx.transferObjects([coinWithBalance({ balance: amountToSend, type: coinType })], receiverAddress);
    tx.setGasBudget(estimatedGas);

    const result = await client.signAndExecuteTransaction({transaction: tx, signer});
    return result.digest;
  }

  async function getGasPrice(): Promise<bigint> {
    return client.getReferenceGasPrice();
  }

  async function getCoinInfo(coinType: string): Promise<CoinMetadata | null> {
    return await client.getCoinMetadata({coinType});
  }

  return {
    isAddressValid,
    getAddressByPrivateKey,
    getBalance,
    getCoins,
    estimateSingleTransferTransactionFee,
    getGasPrice,
    sendSingleTransferTransaction,
    estimateMultipleTransfersTransactionFee,
    sendMultipleTransferTransaction,
    getCoinInfo,
  }
}

export type Web3SuiType = ReturnType<typeof setProviderWeb3>