import {IWeb3DisperseFacade} from "./IWeb3DisperseFacade";
import {
  AddressType,
  IAccount,
  IMapValueByAddress, ITransactionPriorityEnum, NetworkCurrencyEnum,
  NetworkType, PrivateKeyType
} from "../../ConsolidationTool/types";
import {
  IDataEstimateDisperseTransactions,
  IDataEstimateUnitsForMultiTransaction,
  IDataEstimateUnitsForSingleTransaction, IDataSendDisperseTransactions, IEstimateFeeForDisperseResultType
} from "../types";
import {MIST_PER_SUI} from "@mysten/sui/utils";
import {IMultipleTransferSui, setProviderWeb3, Web3SuiType} from "../../../store/web3/web3Sui";
import {GasHelper, sendRequestDelay} from "../../../helpers";
import * as Sentry from "@sentry/react";
import {ITxSuiData} from "../../ConsolidationTool/facades/SUI_Network/SUIFacade";
import {HexStr} from "../../../store/web3/web3";

class SUI_DisperseFacade implements IWeb3DisperseFacade{
  readonly _defaultTransactionPriority: keyof ITransactionPriorityEnum;
  readonly addressesChunkSize: number;
  readonly limitAddresses: number;
  readonly _linkForTxScan: string;
  readonly _network: NetworkType;

  protected readonly _web3Provider: Web3SuiType;

  constructor() {
    this._defaultTransactionPriority = "average";
    this.addressesChunkSize = 200;
    this.limitAddresses = 1000;
    this._linkForTxScan = process.env.REACT_APP_FOR_TX_SUI_SCAN;
    this._network = "sui";
    this._web3Provider = setProviderWeb3(process.env.REACT_APP_SUI_WEB3_HTTP_PROVIDER);
  }

  getTimeout(): number {
    return 50;
  }

  get linkForTxScan() {
    return this._linkForTxScan
  }

  get network() {
    return this._network
  }

  getChunkSize(): number {
    return this.addressesChunkSize;
  }

  getLimitReceiver(): number {
    return this.limitAddresses;
  }

  async estimateTransactions(data: IDataEstimateDisperseTransactions): Promise<IEstimateFeeForDisperseResultType> {
    const notOptimizedFeeInUnit: bigint = await this._estimateUnitsForSingleTransactions(data);

    const optimizedFeeInUnit: bigint = await this._estimateUnitsForMultiTransaction(data);

    return {notOptimizedFeeInUnit, optimizedFeeInUnit};
  }

  async _estimateUnitsForSingleTransactions(data: IDataEstimateUnitsForSingleTransaction): Promise<bigint> {
    const {senderAccount, amountInUnitByReceiver} = data;

    const {getGasPrice, estimateSingleTransferTransactionFee} = this._web3Provider;

    const gasPrice = await getGasPrice();

    const promises: Promise<boolean>[] = [];
    let counter: number = 0;
    let feeSum = BigInt(0);

    for (const [address, amount] of amountInUnitByReceiver.entries()) {
      const dataForEstimate: ITxSuiData = {
        amount,
        senderAccount,
        gasPrice,
        receiverAddress: address,
      };

      const estimateFeeRequest = () => estimateSingleTransferTransactionFee(dataForEstimate)
        .then((fee) => {
          const summaryFee = GasHelper.gasPricePlusPercent(fee, 20);
          feeSum += summaryFee;
          return true;
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              section: "IWeb3Facade",
              facade: "SUI_DisperseFacade",
              method: "_estimateUnitsForSingleTransactions"
            },
            contexts: {
              "_estimateUnitsForSingleTransactions": {
                network: this.network,
                currency: NetworkCurrencyEnum[this.network],
                count_addresses: amountInUnitByReceiver.size,
              }
            }
          });
          return false;
        });

      promises.push(sendRequestDelay(estimateFeeRequest, this.getTimeout() * counter))
      counter++;
    }

    await Promise.all(promises);

    return feeSum;
  }

  async _estimateUnitsForMultiTransaction(data: IDataEstimateUnitsForMultiTransaction): Promise<bigint> {
    const {senderAccount, amountInUnitByReceiver} = data;

    const {getGasPrice, estimateMultipleTransfersTransactionFee} = this._web3Provider;

    const gasPrice = await getGasPrice();

    const dataForEstimate: IMultipleTransferSui = {
      recipients: amountInUnitByReceiver,
      gasPrice,
      senderAccount
    }

    const fee =  await estimateMultipleTransfersTransactionFee(dataForEstimate);

    return GasHelper.gasPricePlusPercent(fee, 20);
  }

  async sendTransactions(data: IDataSendDisperseTransactions): Promise<IMapValueByAddress> {
    const {isOptimizedFee, senderAccount, amountInUnitByReceiver} = data;

    if (isOptimizedFee) {
      return this._sendMultiTransaction({amountInUnitByReceiver, senderAccount});
    } else {
      return this._sendSingleTransactions({amountInUnitByReceiver, senderAccount});
    }
  }

  async _sendSingleTransactions(data: {
    amountInUnitByReceiver: IMapValueByAddress<bigint>;
    senderAccount: IAccount
  }): Promise<IMapValueByAddress<string>> {
    const {senderAccount, amountInUnitByReceiver} = data;

    const {getGasPrice, sendSingleTransferTransaction} = this._web3Provider;

    const gasPrice = await getGasPrice();

    const promises: Promise<boolean>[] = [];
    let counter: number = 0;
    const resultTxReceipt: IMapValueByAddress<HexStr> = new Map();

    for (const [address, amount] of amountInUnitByReceiver.entries()) {
      const dataForSend: ITxSuiData = {
        amount,
        senderAccount,
        gasPrice,
        receiverAddress: address,
      };

      const sendTransactionRequest = () => sendSingleTransferTransaction(dataForSend, false)
        .then((digest) => {
          resultTxReceipt.set(address, digest);
          return true;
        })
        .catch((error) => {
          Sentry.captureException(error, {
            tags: {
              section: "IWeb3Facade",
              facade: "SUI_DisperseFacade",
              method: "_sendSingleTransactions"
            },
            contexts: {
              "_sendSingleTransactions": {
                network: this.network,
                currency: NetworkCurrencyEnum[this.network],
                count_addresses: amountInUnitByReceiver.size,
              }
            }
          });
          return false;
        });

      promises.push(sendRequestDelay(sendTransactionRequest, this.getTimeout() * counter))
      counter++;
    }

    await Promise.all(promises);

    return resultTxReceipt;
  }

  async _sendMultiTransaction(data: {
    amountInUnitByReceiver: IMapValueByAddress<bigint>;
    senderAccount: IAccount
  }): Promise<IMapValueByAddress<string>> {
    const {senderAccount, amountInUnitByReceiver} = data;

    const {getGasPrice, sendMultipleTransferTransaction} = this._web3Provider;
    const resultTxReceipt: IMapValueByAddress<HexStr> = new Map();

    const gasPrice = await getGasPrice();

    const dataForEstimate: IMultipleTransferSui = {
      recipients: amountInUnitByReceiver,
      gasPrice,
      senderAccount
    }

    const digest = await sendMultipleTransferTransaction(dataForEstimate);

    amountInUnitByReceiver.forEach((receiver, address) => {
      resultTxReceipt.set(address, digest);
    });

    return resultTxReceipt;
  }

  async fetchBalanceInUnit(address: AddressType): Promise<bigint> {
    const balanceData = await this._web3Provider.getBalance(address);
    return BigInt(balanceData.totalBalance);
  }

  async setInfoForSendTransaction(senderAccount: IAccount): Promise<void> {}

  privateKeyToAccount(privateKey: PrivateKeyType): IAccount {
    return {
      address: this._web3Provider.getAddressByPrivateKey(privateKey),
      privateKey
    };
  }

  toBaseCurrencyFromUnit(amount: bigint | string | number): string {
    return (Number(amount) / Number(MIST_PER_SUI)).toString();
  }

  toUnitFromBaseCurrency(amount: string | number): bigint {
    return BigInt(Number(amount) * Number(MIST_PER_SUI));
  }

  validateAddress(address: AddressType): boolean {
    return this._web3Provider.isAddressValid(address);
  }

  resetInfoForSendTransaction(): void {
  }

}

export {SUI_DisperseFacade};