import {HexStr} from "../../store/web3/web3";
import {AddressHexStr} from "../../models/chainScan.models";

export type AddressType = string
export type PrivateKeyType = string
export type ReceiverAddressType = AddressType
export type SenderListPrivateKeysType = string // private_key\n private_key ...

export type NetworkType = 'eth' | 'bsc' | 'base' | 'sol' | 'xrp' | 'doge' |
  'arb' | 'opt' | 'pol' | 'avax' | 'opbnb' | 'sui'
export const evmNetworks: NetworkType[] = ['eth', 'bsc', 'base', 'arb', 'opt', 'pol', 'avax', 'opbnb'] as const

export const NetworkCurrencyEnum: Record<NetworkType, string> = {
  eth: "ETH",
  bsc: "BNB",
  opbnb: "BNB",
  base: "ETH",
  arb: "ETH",
  opt: "ETH",
  pol: "POL (base)",
  avax: "AVAX",
  doge: "DOGE",
  sol: "SOL",
  xrp: "XRP",
  sui: "SUI",
} as const

export const NetworkTitleEnum: Record<NetworkType, string> = {
  sol: 'Solana chain',
  base: 'Base chain',
  arb: 'Arbitrum chain',
  opt: 'Optimism chain',
  pol: 'Polygon chain',
  avax: 'Avalanche chain',
  doge: 'Doge chain',
  eth: 'Ethereum chain',
  bsc: 'Binance Smart Chain',
  opbnb: 'opBNB chain',
  xrp: 'Ripple Chain',
  sui: 'SUI Chain',
} as const

export enum StatusTxEnum {
  SKIP = "skip",
  ERROR = "error",
  SUCCESS = "success"
}

export interface IMapValueByAddress<V = PrivateKeyType> extends Map<AddressType, V> {
}

export interface IBalanceDataContract {

}

export type BalanceDataByAddress = IMapValueByAddress<bigint>

export type BalanceDataByCurrencyAndAddress = Map<SelectedCurrencyType, IMapValueByAddress<bigint>>

export interface IBalanceTokenData extends IBalanceDataContract {
  balanceByAddress: IMapValueByAddress<bigint>
  balanceTokenByAddress: IMapValueByAddress<bigint>
}

export interface IDataForGenerateTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  tokenBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<PrivateKeyType>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType | HexStr
}

export interface IDataForSendTransactions {
  baseCurrencyBalanceData: BalanceDataByAddress,
  privateKeyByAddress: IMapValueByAddress<PrivateKeyType>,
  transactionDataByAddress: IMapValueByAddress<IGeneralTxData>,
  transactionPriority: keyof ITransactionPriorityEnum,
  receiverAddress: AddressType | HexStr
}

export interface IAccount {
  address: AddressType,
  privateKey: PrivateKeyType,
}

export interface IGeneralTxData {
}

export interface ITokenDict extends Record<ITokenInfo['symbol'], ITokenInfo> {
}

export type SelectedCurrencyType = keyof ITokenDict | string

export interface ITokenInfo {
  symbol: string
  title: string
  img?: string
  rate?: string
  address?: AddressHexStr | HexStr | AddressType
  link?: string
  group?: string
  website?: string
  desc?: string
  decimal: number
}


export type ITransactionPriorityEnum<V extends string | symbol = string> = {
  [k in V]: string;
};

export type TransactionsDataByCurrencyAndAddress = Map<SelectedCurrencyType, IMapValueByAddress<IGeneralTxData>>

export type FeeDataByCurrencyAndAddress = Map<SelectedCurrencyType, IMapValueByAddress<bigint>>

export type EstimateResultType = {
  txDataByAddress: IMapValueByAddress<IGeneralTxData>,
  feeDataByAddress: IMapValueByAddress<bigint>
}

export type TxHashByCurrencyAndAddress = Map<SelectedCurrencyType, IMapValueByAddress<string>>

export enum GasPriceEnum {
  low = "low",
  medium = "medium",
  high = "high"
}

export enum GAS_LIMITS {
  AUTO = 'auto',
  MANUAL = 'manual'
}

export type GasLimit = typeof GAS_LIMITS[keyof typeof GAS_LIMITS]