import {ITokenDict, NetworkCurrencyEnum} from "../../pages/ConsolidationTool/types";
import suiLogo from '../../assets/images/sui-icon.svg'

const SUI_DEFAULT_IMG = suiLogo as string

const SUITokens: ITokenDict = {
    [NetworkCurrencyEnum.sui]: {
      "title": "SUI",
      "img": suiLogo,
      "rate": "$2.33",
      "address": undefined,
      "link": "https://sui.io//",
      "group": `Base Currency (${NetworkCurrencyEnum.sui})`,
      "website": "https://sui.io//",
      "desc": "",
      "decimal": 9,
      "symbol": NetworkCurrencyEnum.sui
    },
    "ZEUS": {
      "title": "ZEUS Token",
      "img": "https://ipfs.filebase.io/ipfs/QmQsGY23V39cQniSkvMvZpVSCJSrCUkK3F2fm8mRzGAigA",
      "rate": "$0.00",
      "address": "0xadda7e6036b07eba0b9dfbc8ab89c4e972e2ce9f897cb77e01bcf8539fe845c8::zeus_token::ZEUS_TOKEN",
      "link": "",
      "group": `SUI Coin`,
      "website": "",
      "desc": "",
      "decimal": 9,
      "symbol": "ZEUS"
    },
    "POSEIDON": {
      "title": "POSEIDON Token",
      "img": "https://ipfs.filebase.io/ipfs/QmZs9uD8MaMmBBrnMrssDC1TMN71nMZmCY1QWs1PYuzdM5",
      "rate": "$0.00",
      "address": "0x5cd070f18d780c1eff4f0635352baeeac822cbc407867f47ee871d5347e040d8::poseidon_token::POSEIDON_TOKEN",
      "link": "",
      "group": `SUI Coin`,
      "website": "",
      "desc": "",
      "decimal": 9,
      "symbol": "POSEIDON"
    },
}

export {SUITokens, SUI_DEFAULT_IMG}